<template>
  <b-row>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Por Pagina</label>
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          :options="pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <p>GERENCIAMENTO DE GRUPO</p>
    </b-col>
    <b-col
      md="6"
      class="my-1"
    >
      <b-form-group
        label="Filtro"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Procure por Algo"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #cell(avatar)="data">
          <b-avatar :src="data.value" />
        </template>
        <template #cell(code)="data">
          <b-badge
            v-if="data.value"
            variant="light-success"
            style="width: 100%"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-thumbs-up"
            ><path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3" /></svg>
          </b-badge>
          <b-badge
            v-else
            variant="light-danger"
            style="width: 100%"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-thumbs-down"
            ><path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17" /></svg>
          </b-badge>
        </template>

        <template #cell(status)="data">
          <b-badge
            v-if="data.value == 'pendente'"
            variant="warning"
          >
            {{ data.value.toUpperCase() }}
          </b-badge>
          <b-badge
            v-if="data.value == 'aprovado'"
            variant="success"
          >
            {{ data.value.toUpperCase() }}
          </b-badge>
          <b-badge
            v-if="data.value == 'recusado'"
            variant="danger"
          >
            {{ data.value.toUpperCase() }}
          </b-badge>
          <b-badge
            v-if="data.value == 'banido'"
            variant="dark"
          >
            {{ data.value.toUpperCase() }}
          </b-badge>
          <b-badge
            v-if="data.value == 'vencido'"
            variant="danger"
          >
            {{ data.value.toUpperCase() }}
          </b-badge>
        </template>
        <template #cell(statuspayment)="data">
          <b-badge
            v-if="data.value == 'pendente'"
            variant="warning"
          >
            {{ data.value.toUpperCase() }}
          </b-badge>
          <b-badge
            v-if="data.value == 'aprovado'"
            variant="success"
          >
            {{ data.value.toUpperCase() }}
          </b-badge>
          <b-badge
            v-if="data.value == 'recusado'"
            variant="danger"
          >
            {{ data.value.toUpperCase() }}
          </b-badge>
          <b-badge
            v-if="data.value == 'banido'"
            variant="dark"
          >
            {{ data.value.toUpperCase() }}
          </b-badge>
          <b-badge
            v-if="data.value == 'vencido'"
            variant="danger"
          >
            {{ data.value.toUpperCase() }}
          </b-badge>
          <b-badge
            v-if="data.value === 'analise'"
            variant="warning"
          >
            ANALISE
          </b-badge>
          <b-badge
            v-if="data.value === 'aprovado_ext'"
            variant="success"
          >
            APROVADO EXT
          </b-badge>

          <b-badge
            v-if="!data.value"
            variant="dark"
          >
            SEM ORDEM
          </b-badge>
        </template>
        <template #cell(date_end)="data">
          <span
            v-if="!data.value"
            class="font-weight-bolder mb-25"
          >
            <b-badge
              variant="dark"
            >
              NAO INICIADO
            </b-badge>
          </span>
          <span
            v-else
            class="font-weight-bolder mb-25"
          >
            <b-badge
              variant="info"
            >
              {{ formatDate(data.value) }}
            </b-badge>
          </span>
        </template>
        <template #cell(ações)="data">
          {{ data.value }}
          <b-row>
            <!-- botao de view -->
            <b-button
              v-b-modal.modal-prevent-closing
              variant="outline-primary"
              type="button"
              class="btn btn-icon rounded-circle"
              @click="infoModal(data.item)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15px"
                height="15px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-search"
              ><circle
                cx="11"
                cy="11"
                r="8"
              /><line
                x1="21"
                y1="21"
                x2="16.65"
                y2="16.65"
              /></svg>
            </b-button>

            <!-- botao de aprovar -->
            <b-button
              style="margin: 0 2% 0 2%"
              variant="outline-success"
              type="button"
              class="btn btn-icon rounded-circle"
              @click="showMsgBoxAprov(data.item.name, data.item.id)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15px"
                height="15px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-user-check"
              ><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle
                cx="8.5"
                cy="7"
                r="4"
              /><polyline points="17 11 19 13 23 9" /></svg>
            </b-button>

            <!-- botao de reprovar -->
            <b-button
              variant="outline-danger"
              type="button"
              class="btn btn-icon rounded-circle"
              @click="showMsgBoxReprov(data.item.name, data.item.id)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15px"
                height="15px"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-user-x"
              ><path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle
                cx="8.5"
                cy="7"
                r="4"
              /><line
                x1="18"
                y1="8"
                x2="23"
                y2="13"
              /><line
                x1="23"
                y1="8"
                x2="18"
                y2="13"
              /></svg>
            </b-button>
          </b-row>

        </template>
      </b-table>
    </b-col>
    <!-- modal -->
    <b-modal
      v-if="!infomodal.code"
      id="modal-prevent-closing"
      ref="my-modal"
      title="Inserir Código do Grupo"
      ok-title="Salvar"
      cancel-variant="outline-secondary"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="addCode(infomodal.id, infomodal.username)"
    >
      <div>
        <form
          ref="form"
          @submit.stop.prevent="addCode(infomodal.id, infomodal.username)"
        >
          <p>Usuario: {{ infomodal.username }}</p>
          <p>Código: {{ infomodal.link }}</p>
          <b-form-group
            :state="nameState"
            label="Código"
            label-for="name-input"
            invalid-feedback="Código é obrigatório!"
          >
            <b-form-input
              id="name-input"
              v-model="code"
              :state="nameState"
              required
            />
          </b-form-group>
          <!-- PAGAMENTO -->
          <b-form-group
            :state="nameState"
            label="ID do Pagamento"
            label-for="name-input"
            invalid-feedback="ID é obrigatório!"
          >
            <b-form-input
              id="name-input"
              v-model="codepayment"
              placeholder="Preencha 0 caso já estiver pago"
              :state="nameState"
              required
            />
          </b-form-group>
        </form>
      </div>
    </b-modal>
    <b-modal
      v-else
      id="modal-prevent-closing"
      ref="my-modal"
      title="Informações do Grupo"
      ok-title="Fechar"
      cancel-variant="outline-secondary"
      centered
      @show="resetModal"
      @hidden="resetModal"
    >
      <div v-if="infomodal.code">
        <h4> Link do grupo: <a :href="infomodal.link"> {{ infomodal.link }} </a> </h4>
        <h4> Código: {{ infomodal.code }} </h4>
      </div>
    </b-modal>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import {
  BTable, BAvatar, BBadge, BRow, BCol, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton,
} from 'bootstrap-vue'
import funcs from '@/services/func.service'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const user = JSON.parse(localStorage.getItem('admin'))
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10, 30, 50, 100, 200, 300, 400, 500, 600],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      code: '',
      codepayment: '',
      infomodal: [],
      fields: [
        {
          key: 'id', label: 'Cód Interno', sortable: true,
        },
        {
          key: 'username', label: 'Usuario',
        },
        { key: 'name', label: 'Nome', sortable: true },
        { key: 'code', label: 'Código', sortable: true },
        { key: 'status', label: 'Status Grupo', sortable: true },
        { key: 'statuspayment', label: 'Status Pgmt', sortable: true },
        { key: 'date_end', label: 'Fim Contrato', sortable: true },
        'Ações',
      ],
      items: [],
      status: [{
        1: 'pendente', 2: 'recusado', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],

      boxTwo: '',
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.listAllGroups()
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    listAllGroups() {
      const reference = { ref: '309840238', rev: user.id }
      funcs.listAllGroups(reference).then(success => {
        console.log(success)
        if (success.data) {
          this.items = success.data
        }
      })
    },
    formatDate(date) {
      const newdate = `${moment(date)
        .utc()
        .format('DD/MM/YYYY')}`
      this.date_end = newdate
      return newdate
    },
    showMsgBoxAprov(gruponame, grupoid) {
      if (this.codepayment === '') {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'ERRO!!',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'Não é possivel aprovar o grupo sem o código de pagamento',
          },
        })
      } else {
        this.boxTwo = ''
        this.$bvModal
          .msgBoxConfirm(`Voce confirma APROVAR o grupo [ ${grupoid} - ${gruponame} ] `, {
            title: 'Por favor, confirme',
            size: 'sm',
            okVariant: 'success',
            okTitle: 'Sim',
            cancelTitle: 'Não',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value === true) {
              const status = { idgrupo: grupoid, status: 'aprovado' }
              funcs.updateGroup(status).then(success => {
                console.log(success)
                if (success.data) {
                  this.listAllGroups()
                  this.codepayment = ''
                  this.code = ''
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Sucesso!!',
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: 'Grupo aprovado com sucesso.',
                    },
                  })
                }
              })
            }
          })
      }
    },
    showMsgBoxReprov(gruponame, grupoid) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`Voce confirma REPROVAR o grupo [ ${grupoid} - ${gruponame} ] `, {
          title: 'Por favor, confirme',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            const status = { idgrupo: grupoid, status: 'recusado' }
            funcs.updateGroup(status).then(success => {
              console.log(success)
              if (success.data) {
                this.listAllGroups()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Sucesso!!',
                    icon: 'CoffeeIcon',
                    variant: 'success',
                    text: 'Grupo reprovado com sucesso.',
                  },
                })
              }
            })
          }
        })
    },
    infoModal(data) {
      this.infomodal = data
    },
    addCode(id, username) {
      console.log(username)
      if (this.codepayment === '') {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'ERRO!!',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'O CAMPO DE PAGAMENTO NÃO PODE ESTAR VAZIO!',
          },
        })
      } else {
        const status = {
          idgrupo: id, code: this.code, codepayment: this.codepayment, usuario: username,
        }
        funcs.updateGroup(status).then(success => {
          console.log(success)
          if (success.data.code && success.data.code.indexOf('Duplicate entry') >= 0) {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro!!',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'Já existe esse código cadastrado!',
              },
            })
          } else if (success.data) {
            this.listAllGroups()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Sucesso!!',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Códigos inseridos com sucesso!!',
              },
            })
          }
        })
      }
    },

  },
}
</script>
